.banner {
  &--xpromo {
    position: relative;
    margin-top: 84px !important;
    // height: 610px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    padding-top: 35px;
    &:after {
      content: '';
      position: absolute;
      bottom: -30px;
      left: 0;
      display: block;
      width: 100%;
      height: 230px;
      background-color: #efefef;
      border-top-right-radius: 40px;
      border-top-left-radius: 40px;
    }
    &-container {
      display: flex;
      width: 976px;
      height: 550px;
      border: 1px solid white;
      border-radius: 24px;
      position: relative;
      z-index: 9;
      &-wrapper {
        border-radius: 24px;
        overflow: hidden;
      }
    }
    &-video {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -1px;
      width: 100% !important;
      height: 100% !important;
      &_player {
        width: 100%;
        height: 100%;
      }
      &::after {
        display: flex;
        content: '';
      }
    }
    &-image {
      display: block;
      width: 976px;
      height: 550px;
    }
    &-fallback {
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      &-logo {
        position: absolute;
        max-width: 198px;
        max-height: 130px;
        top: 40px;
        left: 40px;
      }
      &-character {
        position: absolute;
        top: 25%;
        right: 40px;
        width: 300px;
      }
    }
  }

  &--slider {
    position: relative;
    margin-top: 35px;
    .swiper {
      &-container {
        border-top-right-radius: 40px;
        border-top-left-radius: 40px;
        margin-bottom: -50px;
        margin-top: 49px;
      }
      &-pagination {
        display: flex !important;
        flex-direction: row !important;
        justify-content: flex-end !important;
        left: auto;
        right: 0;
        bottom: 60px;
        padding-right: 40px;

        &-bullet {
          width: 9px;
          height: 9px;
          margin: 0 !important;
          background-color: #fff;
          opacity: 0.6;
          border-radius: 4px;
          transition: all ease 0.5s;

          &:not(:first-of-type) {
            margin: 0 0 0 15px !important;
          }

          &-active {
            width: 76px;
            opacity: 1;
          }
        }
      }
    }
  }

  &--slide {
    position: relative;
    width: 100%;
    height: 436px;
    &-background {
      position: absolute;
      top: -65px;
      right: -25px;
      bottom: -25px;
      left: -25px;
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
      transition: initial;
      will-change: transform;
      transition-property: transform;
      transition-timing-function: ease-out;
    }
    &-fallback {
      @extend .banner--slide-background;
      background-size: cover;
    }
    &-logo {
      &body {
        position: absolute;
        z-index: 2;
        top: 30%;
        left: calc(50vw - 450px);
        display: flex;
        align-items: center;
        width: 300px;
        height: 175px;
        margin: 0;
        @media screen and (max-width: 1030px) {
          left: calc(50vw - 300px);
        }
      }
      &type {
        max-width: 300px;
        max-height: 175px;
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        will-change: transform;

        @media screen and (max-width: 900px) {
          max-height: 150px;
        }

        &::selection {
          background-color: transparent;
        }
      }
    }
    &-char {
      height: 100%;
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      will-change: transform;

      &body {
        position: absolute;
        z-index: 1;
        bottom: 25px;
        left: calc(50vw + 300px);
        height: 290px;
        margin: 0;
        @media screen and (max-width: 1030px) {
          left: calc(50vw + 100px);
        }
      }

      &::selection {
        background-color: transparent;
      }

      &-new {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: auto 320px;
        background-position: 80% 99%;
        -webkit-transition: background-position initial;
        -o-transition: background-position initial;
        transition: background-position initial;
        will-change: auto;
      }
    }
    &-link {
      position: absolute;
      display: flex;
      width: 100%;
      justify-content: center;
      bottom: 60px;
      z-index: 5;

      .btn {
        display: inline-flex;
        min-width: 186px !important;
        max-width: 386px !important;
        padding: 0 5px;
        margin-bottom: 10px;
        border-radius: 27px;
        box-shadow: 0 3px 15px #000000;
        word-wrap: break-word;
        line-height: 28px;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
}

.curtain {
  display: block;
  background-color: black;
  opacity: 1;
}
