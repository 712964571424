@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.circle-wrapper {
  border-radius: $circle-br;

  &--orange {
    background-color: $web-orange;
  }
}
