$button-background-br: 36px;
$account-button-avatar-br: 20px;
$button-br: 100px;
$circle-br: 50%;
$extra-small-br: 4px;
$small-br: 8px;
$extra-middle-br: 12px;
$middle-br: 16px;
$extra-large-br: 24px;
$wrapper-small-br: 40px 40px 0 0;
