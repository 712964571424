@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.w-main {
  background-color: $pickled-bluewood;
  border-radius: $wrapper-small-br;
  width: 100vw;
  flex: 1 0 auto;
  min-height: 1px;
  position: relative;

  &--sign {
    padding-top: $size-8;
    padding-bottom: $size-12;
    margin-top: -$size-12;
  }

  &--profile {
    max-width: 100vw;
    padding-bottom: 121px;
    padding-top: $size-12;
    margin-top: -$size-9;
  }
}
