@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;
@use 'src/Profile/assets/styles/abstracts/variables/shadows' as *;

.message {
  $root-message: &;

  border-radius: $extra-large-br;
  box-shadow: 0 8px 20px 0 rgba($black, 0.2);
  width: 100%;
  padding: $size-6;
  margin-bottom: $size-10;

  &__circle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    flex-shrink: 0;
    height: $size-10;
    width: $size-10;
    margin-right: $size-3;
  }

  &__parag {
    margin-bottom: $size-3;
    margin-top: $size-2;

    strong {
      color: $white;
      font-weight: 700;
    }
  }

  &__btn {
    margin-right: auto;
    margin-top: $size-6;
  }

  &__input {
    &::placeholder {
      color: $boulder;
    }
  }

  &__label {
    display: block;
    text-align: left;
  }

  &__link {
    white-space: normal;
  }

  &--dark {
    background-color: $fiord;
  }

  &--light {
    background-color: $white;
  }

  &--complete {
    padding: $size-10 $size-12;

    &__parag {
      margin-bottom: $size-5;
      margin-top: $size-3;
    }
  }

  &--not-verified {
    display: flex;
    width: 732px;
    overflow-wrap: anywhere;
    margin-top: $size-10;
    padding: $size-8 $size-12;

    #{$root-message}__parag {
      margin-bottom: $size-4;
      margin-top: $size-2;
    }

    #{$root-message}__img {
      width: $size-20;
      height: $size-20;
      margin-right: $size-6;
    }
  }

  &--caution {
    display: flex;
    padding: $size-6 $size-8;
  }
}
