@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;

@keyframes load {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 10px 10px;
  }
}

.noti {
  margin-bottom: 0!important;
}

.progressBar {
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-bottom: 0.625rem;

  %base_progress_text {
    margin-bottom: 3px;
    font-family: $noto-sans-font-common;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  &--info {
    @extend %base_progress_text;
    color: #525252;
  }

  &--error {
    @extend %base_progress_text;
    color: #ED5C5C;
  }

  &--wrapper {
    display: flex;
    position: relative;
    justify-content: flex-start;
    width: 100%;
    height: 24px;
    padding: 3px 4px 4px 4px;
    border-radius: 100px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    animation: load 1s infinite;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
                      -45deg,
                      rgba(255, 255, 255, 0.2) 25%,
                      transparent 15%,
                      transparent 50%,
                      rgba(255, 255, 255, 0.2) 10%,
                      rgba(255, 255, 255, 0.2) 75%,
                      transparent 35%,
                      transparent
      );
      z-index: 1;
      background-size: 10px 10px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      overflow: hidden;
      animation: load 1s infinite;
    }
  }

  &--scale {
    width: 0;
    height: 18px;
    border-radius: 100px;
    &-install {
      background-image: linear-gradient(to bottom, #1f94cc, #0085c5 0);

    }

    &-update {
      background-image: linear-gradient(to bottom, #f57c00, #f68c1f 0);
    }

    &-error {
      background-color: #ED5C5C;
    }
  }
}