[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: "\eaea";
}
.icon-arrow-down:before {
  content: "\eaeb";
}
.icon-arrow-top:before {
  content: "\eaec";
}
.icon-arrow-right:before {
  content: "\eaed";
}
.icon-arrow2-left:before {
  content: "\eaee";
}
.icon-arrow2-right:before {
  content: "\eaef";
}
.icon-arrow2-up:before {
  content: "\eaf0";
}
.icon-arrow2-down:before {
  content: "\eaf1";
}
.icon-check:before {
  content: "\eaf2";
}
