.popupContainer {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  background: #2c3a54;
  border-radius: 24px;
  padding: 24px 32px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  z-index: 100;
  > * {
    margin: 10px 0;
    text-align: center;
    color: white;
  }
  h3 {
    font-family: "Poppins", "Noto Sans", "Noto Sans JP", "Noto Sans HK",sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }
  p {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  &_update {
    top: 50px;
    button {
      border-radius: 27px;
    }
    .arrow {
      position: absolute;
      left: 50%;
      top: -7px;
      display: block;
      background: transparent;
      border-left: 7px dashed transparent;
      border-right: 7px dashed transparent;
      margin: 0;
      border-bottom: 7px solid #2c3a54;
      overflow: hidden;
      &:after {
        position: absolute;
        left: 50%;
        top: -7px;
        display: block;
        background: transparent;
        border-left: 7px dashed transparent;
        border-right: 7px dashed transparent;
        border-bottom: 7px solid rgb(17, 13, 13);
        overflow: hidden;
        transform: translate(-50%);
        box-shadow: -1px -1px 1px rgba(15, 8, 8, 0.1);
      }
    }
    .later {
      cursor: pointer;
      color: #72aff3;
    }
  }
  &_congratulations {
    bottom: 40px;
    right: 10%;
    padding-bottom: 0;
    animation: fadeIn 800ms;
    > * {
      &:last-child {
        margin: 0;
        padding-bottom: 0;
      }
    }
  }
}

.close {
  position: absolute;
  right: 30px;
  top: 20px;
  display: block;
  margin-top: 0;
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
