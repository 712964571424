@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;

.overlay {
  background-color: rgba($black, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
