@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?s7c4a5');
  src:  url('../../fonts/icomoon.eot?s7c4a5#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon.ttf?s7c4a5') format('truetype'),
  url('../../fonts/icomoon.woff?s7c4a5') format('woff'),
  url('../../fonts/icomoon.svg?s7c4a5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../../fonts/noto-sans/NotoSans-Italic.eot');
  src: url('../../fonts/noto-sans/NotoSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/noto-sans/NotoSans-Italic.ttf') format('truetype'),
  url('../../fonts/noto-sans/NotoSans-Italic.woff2') format('woff2'),
  url('../../fonts/noto-sans/NotoSans-Italic.woff') format('woff'),
  url('../../fonts/noto-sans/NotoSans-Italic.svg#NotoSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../../fonts/noto-sans/NotoSans-Bold.eot');
  src: url('../../fonts/noto-sans/NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/noto-sans/NotoSans-Bold.ttf') format('truetype'),
  url('../../fonts/noto-sans/NotoSans-Bold.woff2') format('woff2'),
  url('../../fonts/noto-sans/NotoSans-Bold.woff') format('woff'),
  url('../../fonts/noto-sans/NotoSans-Bold.svg#NotoSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../../fonts/noto-sans/NotoSans-BoldItalic.eot');
  src: url('../../fonts/noto-sans/NotoSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/noto-sans/NotoSans-BoldItalic.ttf') format('truetype'),
  url('../../fonts/noto-sans/NotoSans-BoldItalic.woff2') format('woff2'),
  url('../../fonts/noto-sans/NotoSans-BoldItalic.woff') format('woff'),
  url('../../fonts/noto-sans/NotoSans-BoldItalic.svg#NotoSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../../fonts/noto-sans/NotoSans.eot');
  src: url('../../fonts/noto-sans/NotoSans.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/noto-sans/NotoSans-Regular.ttf') format('truetype'),
  url('../../fonts/noto-sans/NotoSans.woff2') format('woff2'),
  url('../../fonts/noto-sans/NotoSans.woff') format('woff'),
  url('../../fonts/noto-sans/NotoSans.svg#NotoSans') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans HK';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../../fonts/noto-sans-hk/NotoSansHK-Regular.otf') format("opentype");
}

@font-face {
  font-family: 'Noto Sans HK';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('../../fonts/noto-sans-hk/NotoSansHK-Bold.otf') format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../../fonts/noto-sans-jp/NotoSansJP-Regular.ttf') format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('../../fonts/noto-sans-jp/NotoSansJP-Bold.ttf') format("opentype");
}
