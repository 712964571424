@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;

.notificationsButton {
  all: initial;
  position: absolute;
  z-index: 10;
  bottom: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0085c5;
  min-width: 227px;
  height: 56px;
  color: #ffffff;
  cursor: pointer;
  font-family: $noto-sans-font-common;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  border: none;
  border-radius: 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  right: 40px;
  &__arrow {
    border: solid #c1cbdb;
    border-width: 0 2.25px 2.25px 0;
    display: inline-block;
    padding: 5px;
    margin-left: 10px;
    margin-top: 8px;
    transform: rotate(-135deg);
    &_down {
      transform: rotate(45deg);
      margin-top: -5px;
    }
  }
}

%animationBasis {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-name: animate-fade;
}

.notificationsList {
  position: absolute;
  z-index: 10;
  bottom: 105px;
  right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 448px;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2), 0px 12px 14px rgba(0, 0, 0, 0.1);
  @extend %animationBasis;
  &_wv1 {
    &:last-child {
      padding-bottom: 32px;
    }
  }
  &_item {
    display: flex;
    padding: 16px;
    img {
      display: block;
      width: 84px;
      height: 84px;
      border-radius: 12px;
    }
    h2 {
      font-family: 'Noto Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #393939;
      width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      > wbr {
        display: none;
      }
    }

    &_desc {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 16px;
      &_actions {
        display: flex;
        width: 100%;
        button {
          padding: 4px 10px;
          width: 260px;
          font-size: 18px;
          margin-bottom: 0;
          margin-right: 8px;
        }
        &_reloadButton {
          all: initial;
          cursor: pointer;
          width: auto !important;
        }
        a {
          display: flex;
          justify-content: center;
          width: 50%;
          margin-top: 4px;
          color: #0085c5;
          cursor: pointer;
        }
        svg {
          cursor: pointer;
        }
      }
    }
  }
}

.buttonUpdateContainer {
  width: 50%;
  span {
    padding: 3px 0px !important;
  }
}

%faderPseudoElementBasis {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  height: 64px;
  border-radius: 24px;
  pointer-events: none;
}

.fadingAway {
  position: relative;
  width: 100%;
  &_top {
    &::after {
      @extend %faderPseudoElementBasis;
      @extend %animationBasis;
      top: 0;
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
  &_bottom {
    &::after {
      @extend %faderPseudoElementBasis;
      @extend %animationBasis;
      bottom: 0;
      background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
  &_both {
    &::before {
      @extend %faderPseudoElementBasis;
      @extend %animationBasis;
      top: 0;
      z-index: 1;
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }
    &::after {
      @extend %faderPseudoElementBasis;
      @extend %animationBasis;
      bottom: 0;
      background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
}

.errorReload {
  margin-top: auto;
  margin-bottom: 7px;
  margin-left: 5px;
}

.rightFallback {
  &:before {
    position: absolute;
    top: 16px;
    right: 0;
    z-index: 9999;
    bottom: 16px;
    height: 100%;
    display: block;
    content: '';
    width: 10px;
    background-color: white;
  }
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
