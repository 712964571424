@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.game {
  $root: &;

  border-radius: $extra-large-br;
  overflow: hidden;
  padding-bottom: $size-6;

  @media (min-width: 1031px) {
    padding-bottom: $size-4;
  }

  &__images {
    display: block;
    position: relative;
    margin-bottom: $size-4;

    @media (min-width: 1031px) {
      margin-bottom: $size-3;
    }
  }

  &__img {
    border-radius: $extra-large-br;
    display: block;
    max-width: 100%;
  }

  &__logo {
    position: absolute;
    left: 50%;
    bottom: $size-5;
    max-width: 75%;
    max-height: 25%;
    width: 100%;
    z-index: 1;
    transform: translateX(-50%);
  }

  &__stats {
    display: flex;
    margin-bottom: $size-4;

    @media (min-width: 1031px) {
      margin-bottom: $size-3;
    }

    & + & {
      margin-bottom: $size-3;
    }
  }

  &__stats-item {
    display: flex;
    align-items: center;
  }

  &__stats-item + &__stats-item {
    margin-left: auto;
  }

  &__stats-icon {
    height: $size-10;
    width: $size-10;
    margin-right: $size-2;

    @media (min-width: 1025px) {
      height: $size-8;
      width: $size-8;
    }
  }

  &__stats-name {
    color: $boulder;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &__stats-score {
    color: $mine-shaft;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }

  &__bottom {
    padding-left: $size-6;
    padding-right: $size-6;

    @media (min-width: 1031px) {
      padding-left: $size-4;
      padding-right: $size-4;
    }
  }

  &--external {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23A3A3A3' stroke-width='3' stroke-dasharray='12' stroke-dashoffset='6' stroke-linecap='butt'/%3e%3c/svg%3e");
    position: relative;
    z-index: 1;
    transform: translate3d(0, 0, 0);
    will-change: opacity;

    @media (min-width: 1025px) {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23A3A3A3' stroke-width='3' stroke-dasharray='10' stroke-dashoffset='4' stroke-linecap='butt'/%3e%3c/svg%3e");
    }
  }

  &--installed {
    background-color: $white;
    #{$root}__images {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 222px;
        height: 222px;
        margin: 0;
        border-radius: 50%;
        box-shadow: 100px -100px 0 0 rgba(61, 158, 0, 0.86);
      }

      &::after {
        content: '';
        background-image: url('../../../../assets/images/svg/check-icon-2.svg');
        background-size: cover;
        display: block;
        height: 14px;
        width: 16px;
        position: absolute;
        top: 14px;
        right: $size-3;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.32));
      }
    }
  }

  //override styles from default GameCard component
  .btn {
    margin-bottom: 0;
  }
  button {
    width: 100% !important;
  }
}
