.ExeInstallProgressContainer {
  display: flex;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 796px;
  height: 108px;
  z-index: 11;
  &__data {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    width: 100%;
    header {
      display: flex;
      justify-content: space-between;
    }
    h3 {
      font-family: "Noto Sans", "Noto Sans JP", "Noto Sans HK", "Noto Sans ZH", sans-serif;
      font-size: 32px;
      color: white;
      line-height: 43px;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &:first-of-type {
        width: 590px;
      }
    }
  }
}

.icon {
  border-radius: 16px;
  width: 108px;
  height: 108px;
}

