@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/shadows' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.profile {
  color: $white;
  display: flex;
  margin-bottom: 35px;

  &__edit {
    position: absolute;
    right: 15px;
  }

  &__left {
    margin-right: $size-12;
  }

  &__right {
    flex: 1;
  }

  &__avatar {
    background-color: #000;
    border-radius: $extra-large-br;
    cursor: pointer;
    display: inline-block;
    position: relative;
    overflow: hidden;
    transform: translateZ(0);

    &-img {
      border-radius: $extra-large-br;
      background-color: #000;
      display: block;
      height: 220px;
      width: 220px;
    }

    &-input {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      opacity: 0;
      z-index: 1;
    }

    &::after {
      content: '';
      background-color: rgba(0, 0, 0, 0.64);
      background-image: url('../images/svg/photo-camera.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 35px;
      height: 66px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateY(100%);
      transition: transform 300ms ease;
    }

    &:hover::after {
      transform: translateY(0%);
    }
  }

  &__head {
    align-items: center;
    display: flex;
  }

  &__list {
    font-family: $font-stack-secondary;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    margin-top: $size-6;
  }

  &__list-item {
    margin-bottom: $size-4;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__caption {
    color: $nepal;
    display: inline-block;
    width: 30%;
  }

  &__user-text {
    display: inline-block;
    width: 69%;
    padding-left: 1%;

    &--missed {
      color: $nepal;
    }
  }

  &__message {
    margin-top: $size-4;
  }

  &__change-btn {
    margin-top: $size-4;
  }

  &__form-text-button {
    position: absolute;
    right: 0;
  }

  &__form {
    > .form__field {
      margin-top: $size-6;
    }

    > .form__field ~ .form__field {
      margin-top: 0;
    }
  }

  &__form-btn {
    margin-top: $size-6;
  }

  &__form-btn + &__form-btn {
    margin-left: $size-4;
  }

  &__delimiter {
    margin-bottom: $size-8;
    margin-top: $size-10;
  }
}

.flatpickr-wrapper {
  width: 100%;
}
