@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.input {
  background-color: $white;
  border: 1px solid $blue-bayoux;
  border-radius: $small-br;
  color: $mine-shaft;
  font-size: 16px;
  font-family: $font-stack-secondary;
  font-weight: 500;
  height: $size-10;
  padding: 0 $size-4;
  width: 100%;

  //hide safari default credentials icon in password input
  &::-webkit-credentials-auto-fill-button {
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
    visibility: hidden;
  }

  &:focus {
    background-color: $patterns-blue;
    border: 2px solid $lochmara;
  }

  &::placeholder {
    color: $boulder;
  }

  .has-error & {
    border: 2px solid $pomegranate;
  }

  &.error {
    border: 2px solid $pomegranate;
  }

  &--date {
    display: flex;
    align-items: center;
    &::after {
      position: absolute;
      top: 7px;
      right: 7px;
      width: 24px;
      height: 24px;
      content: "";
      background: url('../../../../../assets/images/svg/calendar.svg') no-repeat scroll;
    }
  }

}
