@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins-Bold.woff2') format('woff2'), url('../../fonts/Poppins-Bold.woff') format('woff'),
    url('../../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('../../fonts/Poppins-SemiBold.woff') format('woff'), url('../../fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins-Medium.woff2') format('woff2'), url('../../fonts/Poppins-Medium.woff') format('woff'),
    url('../../fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-display: swap;
  font-family: 'Noto Sans';
  font-weight: 700;
  src: url('../../fonts/NotoSans-Bold.woff2') format('woff2'), url('../../fonts/NotoSans-Bold.woff') format('woff'),
    url('../../fonts/NotoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Noto Sans';
  font-weight: 600;
  src: url('../../fonts/NotoSans-SemiBold.woff2') format('woff2'),
    url('../../fonts/NotoSans-SemiBold.woff') format('woff'),
    url('../../fonts/NotoSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Noto Sans';
  font-weight: 500;
  src: url('../../fonts/NotoSans-Medium.woff2') format('woff2'), url('../../fonts/NotoSans-Medium.woff') format('woff'),
    url('../../fonts/NotoSans-Medium.ttf') format('truetype');
}
