@use '../abstracts/variables/border-radius' as *;
@use '../abstracts/variables/colors' as *;

.uppercase {
  text-transform: uppercase;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.left-inline {
  text-align: left;
}

.right-inline {
  text-align: right;
}

.center-inline {
  text-align: center;
}

.flex-center {
  margin-left: auto;
  margin-right: auto;
}

.container-all {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 2560px;
  min-height: 100vh;
  min-width: 320px;
  overflow: hidden;
  padding-top: 0.1px;
  position: relative;
}

.nowrap {
  white-space: nowrap;
}

.flex {
  display: flex;
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.hidden {
  display: none !important;
}

.logo-in-card {
  bottom: 8%;
  filter: drop-shadow(0 3px 16px rgba($black, 0.86));
  left: 50%;
  max-height: 25%;
  position: absolute;
  transform: translateX(-50%);
  width: 75%;
}

.hidden-visibility {
  visibility: hidden;
}

.fade-out {
  opacity: 0;
  transition: visibility 0ms linear 300ms, opacity 300ms linear;
  visibility: hidden;
}

.fade-in {
  opacity: 1;
  transition: visibility 300ms linear 0ms, opacity 300ms linear;
  visibility: visible;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
