.game {
    &__list {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: -15px;
      margin-right: -15px;
      padding-bottom: 30px;
      box-sizing: border-box;
    }
  }