@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.headline {
  &--with-icon {
    display: inline-flex;
    position: relative;
    word-break: keep-all;
    width: 100%;

    .circle {
      margin-right: $size-2;
    }
  }
}
