@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;

.games {
  &__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin: 0 0 10px;
    list-style-type: none;

    &--title {
      z-index: 5;
      border-bottom: 3px solid transparent;
    }

    &--item {
      padding: 0 15px;
      margin: 0 0 10px;
      cursor: pointer;

      &:hover {
        .games {
          &__navigation {
            &--link {
              color: #393939;
              transition: 0.2s;
              &::after {
                display: block;
                content: '';
                background-color: #E1E1E1;
                width: calc(100% + 24px);
                height: calc(100% + 16px);
                position: absolute;
                top: -10px;
                left: -12px;
                z-index: 1;
                border-radius: 32px;
              }
            }
          }
        }
      }
      &:active {
        .games {
          &__navigation {
            &--link {
              color: #393939;
              transition: 0.2s;
              &::after {
                display: block;
                content: '';
                background-color: #C2C2C2;
                width: calc(100% + 24px);
                height: calc(100% + 16px);
                position: absolute;
                top: -10px;
                left: -12px;
                z-index: 1;
                border-radius: 32px;
              }
            }
          }
        }
      }
    }

    &--link {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding-bottom: 2px;
      margin: 0;
      border-bottom: 3px solid transparent;
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      font-family: $noto-sans-font-common;
      color: #7a7a7a;
      box-sizing: border-box;
      transition: 0.2s;
    }
  }
}

.active_item {
  color: #393939;
  border-color: #3D9E00;
}
