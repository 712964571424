%navButtonStyles {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 56px;
  top: 247px;
  height: 56px;
  z-index: 10;
  &:hover {
    circle {
      fill: #e1e1e1;
    }
  }
  &:active {
    circle {
      fill: #c2c2c2;
    }
  }
}

.next {
  right: -24px;
  @extend %navButtonStyles;
  > svg {
    transform: rotate(180deg);
  }
}

.prev {
  left: -24px;
  @extend %navButtonStyles;
}
