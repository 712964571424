.otherGamesContainer {
  display: flex;
  justify-content: center;
  background-color: #EFEFEF;
  margin-left: -24px;
  margin-bottom: -60px;
  width: calc(100% + 48px);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  &__list {
    display: block;
    max-width: 978px;
    padding: 23px 0 61px 0;
  }
}

.noEvents {
  flex-grow: 0;
  &__data {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 48px;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    align-self: center;
    h2 {
      font-family: "Poppins", "Noto Sans", "Noto Sans JP", "Noto Sans HK", "Noto Sans ZH", sans-serif;
      font-size: 24px;
      line-height: 36px;
      font-weight: 600;
      margin-top: 16px;
    }
    p {
      text-align: center;
      margin-bottom: 32px;
      span {
        display: block;
        font-family: "Noto Sans", "Noto Sans JP", "Noto Sans HK", "Noto Sans ZH", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}