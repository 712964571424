@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.parag {
  &--with-icon {
    display: inline-flex;
    position: relative;
    width: 100%;
    word-wrap: initial;
    word-break: keep-all;
    text-align: left;
    svg {
      width: $size-5;
      height: $size-5;
      flex-shrink: 0;
      margin-top: 2px;
      margin-right: 10px;
    }
    span {
      word-break: break-all;
    }
  }
}
