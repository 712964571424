@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;
@use 'src/Profile/assets/styles/abstracts/variables/shadows' as *;
@use 'sass:math';

.modal {
  $root: &;

  align-items: flex-start; //fix bug with modal height in ie 11
  display: flex;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;

  &-higher {
    z-index: 1000;
  }

  &__wrapper {
    background-color: $pickled-bluewood;
    border-radius: $extra-large-br;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
    z-index: 1;

    &--sm {
      width: 480px;
      padding: $size-10;

      #{$root}__headline {
        margin-bottom: $size-3;
      }

      #{$root}__parag {
        margin-bottom: $size-3;
      }
    }

    &--lg {
      width: 720px;
      padding: $size-10 $size-18;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &-thankyou {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__headline {
    margin-bottom: $size-4;
    word-break: keep-all;
  }

  &__icon {
    height: 86px;
    width: 86px;
    margin-bottom: $size-4;
  }

  &__img {
    margin-bottom: $size-3;
  }

  &__parag {
    // margin-bottom: $size-6;
  }

  &__text-button {
    margin-top: $size-4;
  }

  &__btns {
    margin-top: $size-6;
    display: flex;
  }

  &__btns--center {
    justify-content: center;
  }

  &__btns--column {
    width: 100%;
    flex-direction: column;
    align-items: center;

    #{$root}__btn + #{$root}__btn {
      margin-top: $size-4;
    }
  }

  &__btns--row {
    flex-direction: row;

    #{$root}__btn + #{$root}__btn {
      margin-left: $size-4;
    }
  }

  &__close {
    position: absolute;
    right: $size-5;
    top: $size-5;
  }

  &__hint {
    position: absolute;
    z-index: 2;
  }

  &__crop-container {
    background-color: $black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 280px;
    width: 280px;
    margin-bottom: $size-8;
    margin-left: auto;
    margin-right: auto;
  }

  &__crop-zoom {
    position: absolute;
    left: -#{$size-15 + $size-12};
    bottom: 0;
    width: calc(100% + #{($size-15 + $size-12) * 2});
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__crop-button {
    background-color: $san-juan;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.4));
    border-radius: $circle-br;
    font-size: 0;
    display: block;
    width: $size-12;
    height: $size-12;
  }
}
