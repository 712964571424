@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.close-button {
  &--primary {
    color: $nepal;

    &:hover {
      color: $white;
    }
  }

  &--secondary {
    color: $boulder;

    &:hover {
      color: $nepal;
    }
  }

  &--in-circle {
    background-color: $fiord;
    border-radius: $circle-br;
    display: flex;
    height: $size-14;
    width: $size-14;

    svg {
      margin: auto;
    }
  }

  svg {
    height: $size-4;
    width: $size-4;
  }
}
