.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.5);
}

.modal {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 720px;
  min-height: 252px;
  padding: 40px 72px;
  border-radius: 24px;
  background-color: #EFEFEF;
  text-align: center;
  h3 {
    font-family: "Poppins", "Noto Sans", "Noto Sans JP", "Noto Sans HK", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #393939;
    margin-bottom: 16px;
  }
  p {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #393939;
    margin-bottom: 24px;
  }
  strong {
    font-family: "Poppins", "Noto Sans", "Noto Sans JP", "Noto Sans HK", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #A3A3A3;
  };
}

.close {
  position: absolute;
  right: 30px;
  top: 20px;
  display: block;
  margin-top: 0;
  cursor: pointer;
  > svg > path {
    fill: #7A7A7A;;
  }
}

.buttonContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;
  > button {
    flex-basis: 200px;
  }
}