.pushCard {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  min-height: 144px;
  margin-bottom: 8px;
  margin-left: 24px;
  margin-right: 24px;
  padding: 24px;
  background: white;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
  }
  &:first-child {
    margin-top: 16px;
  }
  &:last-child {
    margin-bottom: 26px;
  }
  > header {
    display: flex;
    font-family: 'Noto Sans', 'Noto Sans JP', 'Noto Sans HK',sans-serif;
    > h2 {
      display: block;
      max-width: 250px;
      flex: 1;
      height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-family: inherit;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      margin-left: 8px;
    }
    > time {
      display: flex;
      align-items: center;
      font-family: inherit;
      font-size: 14px;
      margin-left: auto;
      > span {
        font-family: inherit;
        background: #ef6c00;
        color: white;
        font-size: 14px;
        margin-left: 8px;
        padding: 2px 8px 2px 8px;
        border-radius: 100px;
      }
    }
    > img {
      width: 20px;
      height: 20px;
    }
  }
  > section {
    display: flex;
    margin-top: 16px;
    align-items: center;
    h3 {
      flex: 1;
      font-family: inherit;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 6px;
    }
    span {
      font-family: inherit;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
    }
    p {
      width: calc(100% - 60px);
    }
    img {
      display: block;
      width: 60px;
      // height: 60px;
      margin-right: 16px;
    }
  }
}
