//LAUNCHER COLORS
$white: #fff;
$black: #000;
$mine-shaft: #393939;
$boulder: #7a7a7a;
$gallery: #efefef;
$mercury: #e1e1e1;
$mercury-2: #e4e4e4;
$camarone: #006800;
$frost: #edf8e6;
$silver: #c2c2c2;
$cloud-burst: #202d46;
$blue-bayoux: #4b5e85;
$blue-haze: #c1cbdb;
$limeade: #3d9e00;
$christi: #54b210;
$fiord: #3a4a69;
$wild-sand-2: #f4f4f4;
$malibu: #72aff3;
$cornflower-blue: #5a9ef1;
$san-juan: #394f72;
$lochmara: #0085c5;
$curious-blue: #24a0db;
$malibu-2: #97c6f8;
$pickled-bluewood: #2c3a54;
$pomegranate: #ef3131;
$web-orange: #ffa726;
$nepal: #99a9c3;
$patterns-blue: #e1f5ff;
$bombay: #b4b9c3;
$cornflower-blue: #5a9ef1;
$cornflower-blue-2: #74aaec;
$foam: #e1f5fc;
