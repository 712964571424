@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;

.placeholder-games {
  text-align: center;

  &__headline {
    margin-bottom: $size-2;
  }

  &__parag {
    margin-bottom: $size-6;
  }

  &__btn {
    display: inline-block;
    padding-left: $size-8;
    padding-right: $size-8;
  }
}
