.pushNotificationsContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 84px;
  right: 0;
  width: 448px;
  height: calc(100vh - 84px);
  background: #EFEFEF;
  z-index: 1999;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.32);
  overflow: hidden;
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  > header {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 0px 24px;
    > h1 {
      font-family: "Poppins", "Noto Sans", "Noto Sans JP", "Noto Sans HK",sans-serif;
      font-weight: 600;
      font-size: 18px;
      align-self: center;
    }
  }
  .nothing {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-top: 48px;
    // margin-left: 34px;
    max-width: 337px;
    min-height: 200px;
    h3 {
      width: 100%;
      text-align: center;
      font-family: "Noto Sans", "Noto Sans JP", "Noto Sans HK", "Noto Sans ZH", sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      word-break: keep-all;
      margin-top: 16px;
      color: #A3A3A3;
    }
  }

  .close {
    border-radius: 50%;
    color: rgba(#393939, 0.6);
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    transition: 0.2s;

    &:hover {
      border-radius: 50%;
      background-color: #e1e1e1;
      > svg {
        > path {
          fill: rgba(#393939, 1);
        }
      }
    }
    &:active {
      background-color: #c2c2c2;
      border-radius: 50%;
      color: rgba(#393939, 1);
    }

    & + & {
      margin-left: 4px;
    }

    &--active {
      color: rgba(#393939, 1);
    }
  }
}