@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;
@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.circle {
  &::before {
    border-radius: $circle-br;
    content: '';
    display: inline-block;
    box-sizing: content-box;
  }

  &--sm {
    &::before {
      height: 9px;
      width: 9px;
    }
  }

  &--md {
    &::before {
      height: $size-4;
      width: $size-4;
    }
  }

  &--orange {
    &::before {
      background-color: $web-orange;
    }
  }

  &--red {
    &::before {
      background-color: $pomegranate;
    }
  }

  &--green {
    &::before {
      background-color: $christi;
    }
  }

  &--with-border {
    &::before {
      border: 1px solid $white;
    }
  }
}
