@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.select {
  $root: &;

  align-items: center;
  appearance: none;
  color: $mine-shaft;
  display: flex;
  position: relative;

  &__btn {
    background-color: $white;
    border: 1px solid $blue-bayoux;
    border-radius: $small-br;
    color: $mine-shaft;
    font-size: 16px;
    font-family: $font-stack-secondary;
    font-weight: 500;
    text-align: left;
    height: $size-10;
    padding: 0 $size-4;
    width: 100%;
    position: relative;
  }

  &__dropdown {
    background-color: $white;
    border-radius: $small-br;
    height: 144px;
    width: 100%;
    padding-bottom: $size-3;
    padding-top: $size-3;
    position: absolute;
    top: $size-11;
    overflow: hidden;
    z-index: 1;
  }

  &__item {
    background-color: $white;
    color: $mine-shaft;
    font-size: 16px;
    font-family: $font-stack-secondary;
    font-weight: 500;
    line-height: $size-10;
    height: $size-10;
    padding: 0 $size-4;

    &:hover {
      background-color: $foam;
    }
  }

  &__icon-arrow-down {
    position: absolute;
    right: $size-4;
    top: 50%;
    margin-top: -8px;
    transform: rotate(0deg);
  }

  &::after {
    // background: url('../images/svg/down-arr.svg') center / contain no-repeat;
    border: 0;
    filter: invert(1);
    height: $size-2;
    transform: rotate(0deg);
    transform-origin: center;
    width: $size-4;
  }

  &.open {
    #{$root}__icon-arrow-down {
      transform: rotate(-180deg);
    }

    #{$root}__btn {
      background-color: $patterns-blue;
      border: 2px solid $lochmara;
    }
  }
}
