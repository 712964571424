@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;

.feature-list {
  color: $nepal;
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    align-items: center;
    flex: 1 1 50%;
    position: relative;
    margin-bottom: $size-6;
    padding-right: $size-2;
  }

  &__text {
    line-height: 1.5;
  }

  &__icon {
    text-align: center;
    flex-shrink: 0;
    margin-right: $size-3;

    img {
      height: $size-13;
      width: $size-13;
    }
  }
}
