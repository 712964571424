@import "../../../../assets/styles/shared/form";

.checkbox {
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }
  &__heating {
    display: flex;
    align-items: center;
    flex: 1 0;
    white-space: nowrap;
  }
  &__title {
    white-space: nowrap;
    font-size: 16px;
    font-weight: 500;
  }
  &__field {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    opacity: 0;
    cursor: inherit;
  }
  &__thumb {
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 3px;
    border-radius: 50%;
    background-color: #FFFFFF;
    transform: translateX(0);
    transition: transform 150ms;

    &--wrapper {
      display: inline-block;
      width: 100%;
      height: 100%;
      will-change: auto;
      transform: translateX(0);
      transition: transform 150ms;
    }
  }
  &__wrapper {
    position: relative;
    width: 100%;
    padding: 6px;
    margin: 0;
    display: flex;
    align-items: center;
    flex: 0;
    border-radius: 13px;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.16) inset;
    background-color: #F5F5F5;
    box-sizing: border-box;
    cursor: pointer;

    &.active {
      background-color: #3D9E00;
      .checkbox {
        &__state {
          color: #ffffff;

          &--left {
            opacity: 1;
          }

          &--right {
            opacity: 0;
          }
        }
        &__thumb {
          transform: translateX(calc(-100% - 6px));

          &--wrapper {
            transform: translateX(100%);
          }
        }
      }
    }
  }
  &__state {
    z-index: 1;
    font-size: 0.6875rem;
    line-height: 0.9375rem;
    font-weight: normal;
    color: #D1D1D1;
    cursor: pointer;

    &--left {
      left: 0.5rem;
      padding-right: 3px;
      opacity: 0;
    }

    &--right {
      right: 0.5rem;
      padding-left: 3px;
      opacity: 1;
    }

    &--center {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      .MuiSwitch-track {
        display: none;
      }
    }
  }
  &__tooltip {
    position: relative;
    display: inline-flex;
    border-radius: 50%;
    color: rgba(#393939, 0.6);
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    transition: 0.2s;
    margin-left: 1px;
  }
}