@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.parag {
  font-family: $noto-sans-font-common;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  word-break: keep-all;

  &--primary {
    color: $white;
  }

  &--secondary {
    color: $bombay;
  }

  &--tertiary {
    color: $mine-shaft;
  }

  &--quarter {
    color: $nepal;
  }
}
