.form {
  &__group {
    position: relative;
    z-index: 0;
    margin-bottom: 0.625rem;

    &.select {
      z-index: 1;
      cursor: pointer;
    }
  }
}
