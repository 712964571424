.select {
    position: relative;
    margin-bottom: 22px;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #393939;
  
    &--value {
      position: relative;
      width: 100%;
      text-align: left;
      padding: 10px 16px;
      border: none;
      border-radius: 8px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
      background: #FFFFFF;
      outline: none;
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      cursor: pointer;
  
      .icon {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        right: 16px;
        margin: auto;
        width: 16px;
        height: 16px;
      }
    }
  
    &--dropdown {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      z-index: 2;
      min-height: 448px;
      padding: 4px;
      box-sizing: border-box;
      width: 100%;
      background-color: #FFFFFF;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      overflow: hidden;
      transition: 0.2s;
  
      @for $i from 0 through 7 {
        @media screen and (max-height: (900px - (46px*$i))) {
          min-height: 365px - (44px*$i);
        }
      }
  
      & > div {
        position: static !important;
      }
  
      .box {
        overflow-x: hidden !important;
        margin-bottom: 0 !important;
      }
    }
  
    &--list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  
    &--item {
      padding: 12px;
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      transition: 0.2s;
      cursor: pointer;
  
      &:hover {
        background: #E1F5FC;
      }
      &:last-child{
        padding-bottom: 24px;
      }
    }
  
    .track {
      &-vertical {
        width: 12px !important;
        padding: 16px 0 !important;
        right: 8px !important;
      }
    }
  
    .thumb {
      &-vertical {
        width: 12px !important;
        background: rgba(122, 122, 122, 0.22) !important;
        border-radius: 8px !important;
      }
    }
  }
  