@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.decor-line {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(194, 194, 194, 0.5) 22.74%,
    #c2c2c2 49.8%,
    rgba(194, 194, 194, 0.5) 76.5%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 21px;
  height: 2px;
  width: 968px;
  max-width: 100%;
  margin: $size-12 auto;
}
