@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@import '../../../assets/styles/shared/base';

.game {
  &-single {
    width: 100%;
    max-width: 790px;
    margin: 0 auto;
    margin-top: -34px;
    &__requirements {
      margin-bottom: 20px;
    }

    &__copyright {
      font-family: $noto-sans-font-common;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      color: #7a7a7a;
    }

    &__title {
      margin: 0 0 8px;
      font-family: $noto-sans-font-common;
      font-size: 30px;
      line-height: 36px;
      font-weight: 600;
      color: #393939;
    }

    &__titleMini {
      margin: 0 0 8px;
      font-family: $noto-sans-font-common;
      font-size: 22px;
      line-height: 28px;
      font-weight: 600;
      color: #393939;
    }

    %description {
      font-family: $noto-sans-font-common;
      margin: 0 0 10px;
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      color: #393939;
    }

    &__languages {
      @extend %description;
      line-height: 21px;
    }

    &__description {
      @extend %description;

      strong,
      b {
        font-weight: 700;
      }

      i {
        font-style: italic;
      }

      p {
        margin-bottom: 10px;
        font-weight: 500;
        line-height: 22px;
      }

      dt {
        display: inline-block;
        margin-right: 5px;
        color: #7a7a7a;
        font-weight: bold;
      }
      dd {
        display: inline-block;
        margin: 0;
        font-weight: 500;
      }

      button {
        font-weight: 600;
      }

      ul {
        margin-left: 22px;
        list-style-type: disc;
        line-height: 0px;
      }
      li {
        margin: 0;
        line-height: 22px;
      }
      a {
        @extend %clickableExternalLink;
      }
      h2 {
        @extend .game-single__title;
      }
      h4 {
        @extend .game-single__title;
        font-size: 16px;
      }
    }

    &__description[lang=zh_tw] {
      font-family: "Noto Sans", "Noto Sans HK", "Noto Sans JP", sans-serif;
    }

    &__rating {
      margin-bottom: 20px;
      &--box {
        width: 52px;
        height: 41px;
        background-color: #274193;
        border-radius: 8px;
        color: white;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        p {
          position: relative;
          float: left;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &__link {
      margin-bottom: 20px;
      button {
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 22px;
      }
    }

    &__list {
      margin: 0;

      &.list {
        &--disc {
          padding-left: 15px;
          list-style-type: disc;
        }
        &--none {
          padding: 0;
          list-style-type: none;
        }
      }

      &--text {
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        color: #393939;
      }
    }

    .MuiFormControlLabel {
      &-root {
        margin: 0;
      }
    }
  }

  &__description {
    margin-bottom: 20px;
  }

  &__languages {
    margin-bottom: 20px;
  }

  &-banner {
    position: relative;
    width: 100%;
    height: 436px;
    margin-bottom: -50px;
    margin-top: 83px;
    overflow: hidden;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
  }

  &__promo-plate {
    margin-bottom: 60px;
    // margin-top: 24px;
  }
}
