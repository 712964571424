@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.header {
  background-color: $cloud-burst;
  display: flex;
  padding-bottom: $size-14;
  padding-top: $size-10;

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__headline {
    text-align: center;
    width: 100%;
    margin-bottom: $base-size;
  }

  &__right {
    overflow: hidden;
    flex: 1;
    margin-left: $size-4;
    margin-top: 23px;
  }

  &__maskot {
    height: 240px;
    width: 150px;
    margin-bottom: -76px;
  }
}
