.bell {
  border-radius: 50%;
  color: rgba(#393939, 0.6);
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  transition: 0.2s;

  &:hover {
    border-radius: 50%;
    background-color: #e1e1e1;
    > svg {
      > path {
        fill: rgba(#393939, 1);
      }
    }
  }
  &:active {
    background-color: #c2c2c2;
    border-radius: 50%;
    color: rgba(#393939, 1);
  }

  & + & {
    margin-left: 4px;
  }

  &--active {
    color: rgba(#393939, 1);
  }

  &__new {
    position: absolute;
    display: inline-flex;
    align-items: center;
    top: 5px;
    right: 5px;
    max-width: 100px;
    height: 16px;
    background: #ef6c00;
    font-family: 'Noto Sans', 'Noto Sans JP', 'Noto Sans HK', 'Noto Sans ZH', sans-serif;
    font-size: 10px;
    line-height: 16px;
    padding: 4px;
    color: white;
    border-radius: 100px;
  }
}

.activePanel {
  svg {
    > path {
      fill: rgba(#393939, 1);
    }
  }
}

@keyframes dingding {
  0% {transform: rotate(35deg);}
  12.5% {transform: rotate(-30deg);}
  25% {transform: rotate(25deg);}
  37.5% {transform: rotate(-20deg);}
  50% {transform: rotate(15deg);}
  62.5% {transform: rotate(-10deg)}
  75% {transform: rotate(5deg)}
  100% {transform: rotate(0);}
}

.notify {
  animation: dingding 1s ease-out;
  transform-origin: center top;
}
