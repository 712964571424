@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  font-family: $noto-sans-font-common;

  &--default {
    padding: 10px 12px;
    border: none;
    box-shadow: 0 5px 10px rgba(122,122,122,0.3);
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    &.btn {
      &--blue {
        background-color: #0085C5;
        color: #fff;
      }
      &--green {
        background-color: #3D9E00;
        color: #fff;
        &:hover {
          background-color: #54B210;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        }
        &:active {
          background-color: #54B210;
          box-shadow: none;
        }
      }
      &--yellow {
        background-color: #F7C600;
        color: #fff;
      }
      &--orange {
        font-family: $noto-sans-font-common;
        background-color: #EF6C00;
        color: #fff;
      }
      &--grey {
        background-color: #F4F4F4;
        color: #393939;
      }
      &--forceUpdate {
        width: 222px;
      }
      &_border {
        border: 4px solid white;
      }
    }
  }

  &--link {
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;

    &.btn {
      &--blue {
        color: #0085C5;
      }
      &--green {
        color: #3D9E00;
      }
      &--yellow {
        color: #F7C600;
      }
    }
  }

  &--round {
    min-height: 40px;
    border-radius: 20px;
  }

  &--outline {

  }

  &--close {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    z-index: 2;

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 24px;
      height: 4px;
      border-radius: 50px;
      transition: 0.2s;
    }

    &:before {
      transform: translate(-50%,-50%) rotateZ(45deg);
    }
    &:after {
      transform: translate(-50%,-50%) rotateZ(135deg);
    }

    &.btn {
      &--white {
        &:after,
        &:before {
          background: #FFFFFF;
        }
      }
      &--grey {
        &:after,
        &:before {
          background: #7A7A7A;
        }
      }
    }
  }
  &--dropdown {
    font-family: "Noto Sans", sans-serif;
  }
}

.large {
  min-height: 56px;
}

.medium {
  min-height: 48px;
}


