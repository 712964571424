.installedGamesBanner {
  position: relative;
  width: 100%;
  height: 27.25rem;
  margin-bottom: -6.5rem;
  background-size: cover;
  background-position: 50% 50%;
  margin-top: 85px;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}