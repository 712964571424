@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.w-btn {
  border-radius: $button-br;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  color: $white;
  cursor: pointer;
  font-family: $font-stack-primary;
  font-weight: 700;
  white-space: nowrap;
  transition: background-color 200ms;

  &:hover {
    text-decoration: none;
  }

  &--mode-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1.5;
    height: $size-10;
    width: 100%;
  }

  &--mode-2 {
    font-size: 20px;
    line-height: 1.6;
    height: $size-12;
    padding-left: 54px;
    padding-right: 54px;
  }

  &--mode-3 {
    font-size: 16px;
    line-height: 1.5;
    height: $size-10;
    padding-left: $size-10;
    padding-right: $size-10;
  }

  &--mode-4 {
    font-size: 20px;
    height: $size-12;
    width: 280px;
  }

  &--mode-5 {
    font-size: 20px;
    height: $size-12;
    width: 200px;
  }

  &--mode-6 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 1.5;
    height: $size-12;
    width: 100%;
  }

  &--mode-7 {
    font-size: 20px;
    line-height: 1.5;
    height: $size-12;
    padding-left: $size-8;
    padding-right: $size-8;
  }

  &--mode-8 {
    font-size: 20px;
    line-height: 1.5;
    height: $size-12;
    width: 100%;

    @media (min-width: 1031px) {
      height: $size-10;
      font-size: 16px;
    }
  }

  &--primary {
    background-color: $limeade;

    &:hover {
      background-color: $christi;
    }
  }

  &--secondary {
    background-color: $fiord;

    &:hover {
      background-color: $blue-bayoux;
    }
  }

  &--tertiary {
    background-color: $lochmara;

    &:hover {
      background-color: $curious-blue;
    }
  }

  &--quarter {
    background-color: $wild-sand-2;

    &:hover {
      // ask designer
    }
  }
}
