.promo {
  position: absolute;
  left: 0;
  right: 0;
  top: 85px;
  display: flex;
  max-width: 900px;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  > * {
    margin-bottom: 13px;
  }
  h2,
  h3,
  span {
    font-family: 'Noto Sans', 'Noto Sans JP', 'Noto Sans HK',sans-serif;
  }
  h2 {
    font-size: 44px;
    color: #448ADB;
    line-height: 59.93px;
    font-weight: 700;
  }
  h3 {
    font-family: "Poppins", "Noto Sans", "Noto Sans JP", "Noto Sans HK",sans-serif;
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
    color: #FE6017;
  }
  sub {
    color: rgba(28, 59, 116, 0.7);
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
  }
}
.promoDescription {
  display: flex;
  width: 700px;
  font-family: 'Noto Sans', 'Noto Sans JP', 'Noto Sans HK',sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1C3B74;
  text-align: center;
}
