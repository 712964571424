@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.error-message {
  color: $white;
  font-family: $font-stack-secondary;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  display: block;
  margin-top: $size-2;
  padding-left: 22px;
  position: relative;
  text-align: left;
  word-break: keep-all;

  &::before {
    background-image: url('../../../../../assets/images/svg/error-icon.svg');
    background-size: cover;
    border-radius: $circle-br;
    content: '';
    height: $size-4;
    width: $size-4;
    position: absolute;
    left: 0;
    top: $base-size;
  }
}
