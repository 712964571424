@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.input-password {
  position: relative;

  &__btn {
    position: absolute;
    right: $size-4;
    top: 50%;
    transform: translateY(-50%);
  }

  &__visible-icon {
    height: 14px;
    width: 24px;
  }

  &__hidden-icon {
    height: 19px;
    width: 24px;
  }
}
