.InstallationWrapper {
  z-index: 9998;
}

.InstallationContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: relative;
  max-width: 1499px;
  height: 100vh;
  z-index: 10;
}

.g5Logo {
  position: absolute;
  top: 57px;
  left: 24px;
  width: 250px;
  height: 58px;
  z-index: 10;
}

.gameLogo {
  position: absolute;
  max-width: 198px;
  top: 57px;
  right: 24px;
  z-index: 10;
}

.character {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  max-width: 50%;
  max-height: 664px;
}

.background {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  &::before {
    content: '';
    z-index: 2;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 50%);
  }
  &::after {
    content: '';
    z-index: 2;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0) 50%);
  }
}

.panel {
  display: block;
  height: 32px;
  background-color: #efefef;
  z-index: 9999;
  position: relative;
  &::after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 138px;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.04);
  }
}
