@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.label {
  color: $blue-haze;
  display: block;
  font-family: $font-stack-secondary;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  margin-bottom: $size-2;
}
