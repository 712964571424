@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9998;
  background-color: #efefef;
  color: #7a7a7a;
  min-width: 1000px;

  &::after {
    display: block;
    content: '';
    position: absolute;
  }
  &--wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 40px 8px;
  }

  &--logo {
    display: flex;
    margin-right: 16px;
    > img {
      height: 37px;
    }
  }

  &__nav {
    &--list {
      display: flex;
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    &--arrows {
      // margin-right: 16px;
    }

    &--item {
      display: flex;
      &:not(:last-of-type) {
        margin-right: 4px;
      }
    }
    &--navItem {
      display: flex;
      &:first-of-type:last-of-type,
      &:not(:last-of-type) {
        // margin-right: 25px;
      }
    }
    &--link {
      font-family: $noto-sans-font-common;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-decoration: none;
      color: #7a7a7a;
      white-space: nowrap;
      transition: 0.2s;
      cursor: pointer;
      padding: 6px 16px;
      border-radius: 23px;

      &.activeLink {
        color: #393939;
      }
      &:hover {
        background-color: #e1e1e1;
        border-radius: 23px;
        color: #393939;
      }
      &:active {
        background-color: #c2c2c2;
        border-radius: 23px;
        color: #393939;
      }
    }

    &--btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      position: relative;
      width: 40px;
      height: 40px;
      padding: 0;
      border-radius: 2px;
      border: none;
      outline: none;
      box-shadow: none;
      background: transparent;
      color: #7a7a7a;
      opacity: 0.5;
      transition: 0.2s;

      &:nth-of-type(1) {
        // margin-right: 4px;
      }
      &:nth-of-type(2) {
        > img {
          transform: rotate(180deg);
        }
      }
    }
  }

  .icon {
    font-size: 17px;
  }

  //PERSONAL STYLES
  &__account-button {
    flex-shrink: 0;
    margin-left: 4px;
  }

  &__popup {
    top: 84px;
    right: 24px;
  }
}

.arrows {
  position: relative;
  justify-content: space-between;
}

.activeArrow {
  opacity: 1;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    opacity: 1;
  }
  &:hover {
    display: flex;
    content: '';
    background-color: #e1e1e1;
    border-radius: 50%;
  }
  &:active {
    display: flex;
    content: '';
    border-radius: 50%;
    background-color: #c2c2c2;
  }
}

.historyArrows {
  margin-right: 40px;

  svg {
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      fill: #393939;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.underline {
  display: flex;
  justify-content: center;
  > div {
    width: 24px;
    height: 2px;
    border-radius: 2px;
    background-color: #0999d8;
  }
}

.panel {
  display: block;
  height: 32px;
  background-color: #efefef;
  &::after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 138px;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.tooltip {
  position: absolute;
  top: 34px;
  display: inline-block;
  width: auto;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // min-width: 134px;
  // max-width: 400px;
  //word-wrap: break-word;
  background-color: rgba(57, 57, 57, 0.8);
  border-radius: 4px;
  color: #ffffff;
  padding: 2px 8px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  z-index: 9999;
}
