@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.form__field {
  position: relative;
  margin-bottom: $size-4;

  &--full {
    width: 100%;
  }

  &--half {
    display: inline-block;
    width: calc(50% - 8px);

    &:nth-child(odd) {
      margin-right: $size-4;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
