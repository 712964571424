@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.profile-games {
  .recommended-games {
    > div {
      padding-top: 0;
    }

    h2 {
      color: $mine-shaft;
      margin-bottom: $size-8;
      font-size: 30px;
      line-height: 36px;
      font-weight: 600;
    }
  }
}
