@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.checkbox {
  color: $white;
  line-height: 1;
  text-align: left;
  margin-top: $size-4;

  &__label {
    display: inline-flex;
  }

  &__back {
    background-color: $white;
    border: 1px solid $blue-bayoux;
    border-radius: $extra-small-br;
    flex-shrink: 0;
    height: $size-6;
    width: $size-6;
    margin-right: $size-2;
    position: relative;
  }

  &__icon {
    background-color: $limeade;
    border-radius: $extra-small-br;
    display: none;
    height: $size-6;
    left: -1px;
    position: absolute;
    top: -1px;
    width: $size-6;

    svg {
      height: 54%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
    }
  }

  &__txt {
    margin-bottom: 0;
    margin-top: 0;
  }

  &.has-error {
    .checkbox__back {
      border: 2px solid $pomegranate;
    }
  }

  input {
    &:focus + .checkbox__label .checkbox__back {
      background-color: $patterns-blue;
      border-color: $lochmara;
    }

    &:checked + .checkbox__label .checkbox__icon {
      display: block;
      overflow: hidden;
    }
  }
}
