$base-size: 4px;
$size-2: $base-size * 2;
$size-3: $base-size * 3;
$size-4: $base-size * 4;
$size-5: $base-size * 5;
$size-6: $base-size * 6;
$size-7: $base-size * 7;
$size-8: $base-size * 8;
$size-9: $base-size * 9;
$size-10: $base-size * 10;
$size-11: $base-size * 11;
$size-12: $base-size * 12;
$size-13: $base-size * 13;
$size-14: $base-size * 14;
$size-15: $base-size * 15;
$size-16: $base-size * 16;
$size-17: $base-size * 17;
$size-18: $base-size * 18;
$size-19: $base-size * 19;
$size-20: $base-size * 20;
$size-21: $base-size * 21;
$size-22: $base-size * 22;
$size-23: $base-size * 23;
$size-24: $base-size * 24;
$size-25: $base-size * 25;
$size-26: $base-size * 26;
$size-27: $base-size * 27;
$size-28: $base-size * 28;
$size-29: $base-size * 29;
$size-30: $base-size * 30;
$size-31: $base-size * 31;
$size-32: $base-size * 32;
$size-33: $base-size * 33;
$size-34: $base-size * 34;
$size-35: $base-size * 35;
$size-36: $base-size * 36;
$size-37: $base-size * 37;
$size-38: $base-size * 38;
$size-39: $base-size * 39;
$size-40: $base-size * 40;
$size-41: $base-size * 41;
$size-42: $base-size * 42;
