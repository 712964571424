@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/base/_index.scss';

.sign-in {
  background-color: $pickled-bluewood;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  min-width: 1000px;

  &__message {
    margin-bottom: $size-6;
  }

  &__parag {
    margin-bottom: $size-4;
    margin-top: 0;
  }

  &__form {
    margin-bottom: $size-4;

    .w-btn {
      margin-top: $size-4;
    }
  }

  &__input {
    height: $size-12;
  }

  &__close-btn {
    display: flex;
    align-items: center;
    position: fixed;
    top: $size-14;
    left: $size-16;
    z-index: 1;
    svg {
      width: 18px;
      height: $size-4;
      margin-right: $size-3;
    }
  }

  &__delimiter {
    margin-bottom: $size-4;
    margin-top: $size-4;
  }
}
