@keyframes load {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.Progress {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.625rem;
  &__wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 48px;
    padding: 2px 3px;
    border-radius: 100px;
    background-color: #ffffff;
    box-sizing: border-box;
    margin-top: 16px;
    overflow: hidden;
  }
  &__bar {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    animation: load 1s infinite;
    // box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    &-update {
      background-image: linear-gradient(to bottom, #f57c00, #f68c1f 0);
    }
    &-install {
      background-image: linear-gradient(to bottom, #1f94cc, #0085c5 0);
    }
    &::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
      );
      z-index: 1;
      background-size: 10px 10px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      overflow: hidden;
      animation: load 1s infinite;
    }
  }
}
