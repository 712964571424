.support {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.link {
  border-radius: 50%;
  color: rgba(#393939, 0.6);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  position: relative;
  transition: 0.2s;

  &:hover {
    border-radius: 50%;
    background-color: #e1e1e1;
    color: rgba(#393939, 1);
  }
  &:active {
    background-color: #c2c2c2;
    border-radius: 50%;
    color: rgba(#393939, 1);
  }

  & + & {
    margin-left: 4px;
  }

  &--active {
    color: rgba(#393939, 1);
  }
}

.modal {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

%basisText {
  font-family: 'Noto Sans', 'Noto Sans JP', 'Noto Sans HK', 'Noto Sans ZH', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #efefef;
  width: 480px;
  border-radius: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  > * {
    margin-bottom: 16px;
  }
  h2 {
    width: 400px;
    text-align: center;
    font-family: 'Noto Sans', 'Noto Sans JP', 'Noto Sans HK', 'Noto Sans ZH', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #393939;
  }
}

.modalDescription {
  width: 400px;
  text-align: left;
  font-family: 'Noto Sans', 'Noto Sans JP', 'Noto Sans HK', 'Noto Sans ZH', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #393939;
  .email {
    color: #0999d8;
  }
}

.modalAgreement {
  font-family: 'Noto Sans', 'Noto Sans JP', 'Noto Sans HK', 'Noto Sans ZH', sans-serif;
  width: 400px;
  color: #7a7a7a;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  > a {
    cursor: pointer;
    color: #0085c5;
  }
}

.subscribeButton {
  width: 248px;
  max-width: 100%;
  height: 48px;
  font-family: 'Poppins', 'Noto Sans', 'Noto Sans JP', 'Noto Sans HK', 'Noto Sans ZH', sans-serif;
  color: white;
  background-color: #0085c5;
  padding: 6px;
  margin-bottom: 0.625rem;
  border-radius: 32px;
  border: none;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.32);
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  transition: 0.2s;
  &:hover:enabled {
    filter: brightness(1.1);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.62);
  }
  &:disabled {
    color: #7a7a7a;
    background-color: #f4f4f4;
    box-shadow: none;
    border: 1px solid #e4e4e4;
    cursor: wait;
  }
}

.closeModal {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

%calvinPosition {
  position: absolute;
  top: -171px;
}

.SUCCESS,
.ALREADY_SUBSCRIBED {
  @extend %calvinPosition;
  right: 110px;
}

.ERROR {
  @extend %calvinPosition;
  right: 139px;
}
