.inputField {
  display: flex;
  flex-direction: column;
  width: 400px;
  &__input {
    font-family: 'Noto Sans', 'Noto Sans JP', 'Noto Sans HK', 'Noto Sans ZH', sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-style: normal;
    font-weight: 500;
    border-radius: 8px;
    background: #FFFFFF;
    height: 40px;
    width: 100%;
    padding: 10px 16px;
    border: 2px solid transparent;
    position: relative;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    &:focus-visible {
      outline:none !important;
    }
    &::selection {
      background-color: #0085C5;;
      color: white;
    }
  }

  &__error {
    display: inline-flex;
    align-items: center;
    font-family: "Noto Sans", "Noto Sans JP", "Noto Sans HK", "Noto Sans ZH", sans-serif;
    font-size: 14px;
    line-height: 19px;
    height: 19px;
    color: #EF3131;
    margin-top: 3px;
    animation-delay: 5s;
    img {
      margin-right: 5px;
    }
    &--input {
      border: 2px solid #EF3131!important;
    }
  }
}