@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.headline {
  font-family: $noto-sans-font-common;
  font-weight: 600;
  line-height: 36px;
  margin-top: 0;
  margin-bottom: 0;

  &--primary {
    color: $white;
  }

  &--secondary {
    color: $mine-shaft;
  }

  // Discuss with designer headline defferent font-families
  // &--font-primary {
  //   font-family: $font-stack-primary;
  //   font-weight: 600;
  // }

  // &--font-secondary {
  //   font-family: $font-stack-secondary;
  //   font-weight: 700;
  // }

  &--size-1 {
    font-size: 32px;
  }

  &--size-3 {
    font-size: 18px;
  }

  &--size-2 {
    font-size: 20px;
  }

  &--size-4 {
    font-size: 30px;
  }
}
