.promo-gift {
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;

  &--sm {
    height: 72px;
    width: 72px;
    left: -12px;
    top: -12px;
  }

  &--lg {
    height: 92px;
    width: 92px;
    left: -20px;
    top: -20px;
  }
}
