@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;

.EventsContainer {
  padding-top: 48px;
  padding-bottom: 48px;
}

.EventList {
  display: flex;
  flex-direction: column;
  h3 {
    position: relative;
    margin: 0 0 4px;
    font-family: $noto-sans-font-common;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    display: -webkit-box;
    max-width: 100%;
    color: #393939;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.EventCard {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 180px;
  max-height: 248px;
  border-radius: 24px;
  background-color: white;
  padding: 8px;
  // box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.32);
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 400ms;
  margin-bottom: 20px;
  &:hover {
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.12);
  }
  &__image {
    display: inline-block;
    border-radius: 20px;
    width: 412px;
    height: 232px;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    &_game {
      width: 288px;
      height: 168px;
    }
  }

  &_header {
    font-size: 22px;
    line-height: 27px;
    font-weight: 600;
    word-wrap: break-word;
    &_game {
      font-size: 22px!important;
      line-height: 28px!important;
    }
  }

  &__footer {
    display: flex;
    width: 502px;
    height: 44px;
    justify-content: space-between;
    margin-top: auto;
    position: absolute;
    bottom: 32px;
    // margin-top: 16px;
    > div:nth-of-type(1) {
      display: flex;
      align-items: center;
      cursor: pointer;
      p {
        max-width: 290px;
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 160px;
      margin-top: 10px;
      button {
        width: 100%;
      }
    }

    img {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      margin-right: 12px;
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 24px;
    padding: 24px 24px 0 0;
    width: 400px;
    &_game {
      margin-left: 20px;
      padding: 16px 16px 0 0;
    }
    > * {
      word-break: keep-all;
    }
    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      // word-break: break-word;
    }
    &_shortDescription {
      display: -webkit-box;
      position: relative;
      max-width: 100%;
      font-family: $noto-sans-font-common;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      //margin-bottom: 16px;
      &:after {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 88px;
        height: 24px;
        content: '';
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0.12%, rgba(255, 255, 255, 0.96) 79.89%, #FFF 99.88%);
        backdrop-filter: blur(0.5px);
      }
    }
  }
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 580px;
  max-height: 714px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 24px;
  padding: 8px 0 8px 8px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.12);
  h3 {
    font-size: 22px;
    line-height: 28px;
    font-family: $noto-sans-font-common;
    margin-right: 25px;
    word-wrap: break-word;
  }
  &__footer {
    display: block;
    padding: 16px 24px;
    background-color: #f4f4f4;
    border-radius: 16px;
    height: 80px;
    margin-top: 2px;
    p {
      font-family: $noto-sans-font-common;
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
    }
    footer {
      width: 516px;
      bottom: 24px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 32px 4px 0 32px;
    // margin-bottom: 24px;
    word-break: keep-all;
    > * {
      margin-bottom: 8px;
    }
    &__description {
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      padding-right: 50px;
      // margin-bottom: 15px;
    }
  }
  &__image {
    display: block;
    width: 564px;
    height: 316px;
    border-radius: 20px;
    margin-bottom: 2px;
  }
  &__close {
    background-color: #f4f4f4;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 12px;
    top: 12px;

    &-icon {
      path {
        fill: #7a7a7a;
      }
    }

    &:hover {
      background-color: #e1e1e1;

      &-icon {
        path {
          fill: #393939;
        }
      }
    }

    &:active {
      background-color: #c2c2c2;

      &-icon {
        path {
          fill: #393939;
        }
      }
    }
  }

  &__close-icon {
    height: 16px;
    width: 16px;
  }

  &__link {
    display: block;
  }
}

%shadowBasis {
  display: block;
  position: absolute;
  content: '';
  width: 95%;
  height: 30px;
  backdrop-filter: blur(0.5px);
}

.shadow {
  &_top {
    position: relative;
    &:after {
      @extend %shadowBasis;
      top: -1px;
      background: linear-gradient(360deg, rgba(255, 255, 255, 0.00) 0.12%, rgba(255, 255, 255, 0.96) 79.89%, #FFF 99.88%);
    }
  }
  &_bottom {
    position: relative;
    &:after {
      @extend %shadowBasis;
      bottom: -1px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0.12%, rgba(255, 255, 255, 0.96) 79.89%, #FFF 99.88%);
    }
  }
  &_both {
    position: relative;
    &:before {
      @extend %shadowBasis;
      top: -1px;
      z-index: 1;
      background: linear-gradient(360deg, rgba(255, 255, 255, 0.00) 0.12%, rgba(255, 255, 255, 0.96) 79.89%, #FFF 99.88%);
    }
    &:after {
      @extend %shadowBasis;
      bottom: -2px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0.12%, rgba(255, 255, 255, 0.96) 79.89%, #FFF 99.88%);
    }
  }
}
