@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.games-list {
  &__headline {
    margin-bottom: $size-2;
  }

  & + & {
    margin-top: $size-12;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 36px;
    margin-top: $size-8;

    @media (min-width: 1031px) {
      grid-template-columns: repeat(4, 222px);
      grid-gap: 30px;
    }
  }
}
