@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.section-container {
  position: relative;

  &--primary {
    background-color: $white;
  }

  &--secondary {
    background-color: $gallery;
  }

  &--tertiary {
    background-color: $mercury-2;
  }

  &--type-1 {
    border-radius: $wrapper-small-br;
    margin-top: -$size-12;
    padding-bottom: $size-24;
    padding-top: $size-12;
  }

  &--type-2 {
    border-radius: $wrapper-small-br;
    margin-top: -$size-12;
    padding-bottom: $size-12;
    padding-top: $size-12;
  }

  &--type-3 {
    border-radius: $wrapper-small-br;
    padding-bottom: $size-10;
    padding-top: $size-10;
    margin-top: -$size-10;
  }
}
