@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.account-button {
  $root: &;

  background-color: transparent;
  color: $boulder;
  height: $size-10;
  // width: $size-40;
  position: relative;
  transition: 0.2s;
  border-radius: $circle-br;

  @media (min-width: 1024px) {
    border-radius: $button-background-br;
    // min-width: 140px;
    padding: $base-size $base-size $base-size $size-3;
  }

  &:hover {
    background-color: $mercury;
    color: $mine-shaft;
  }

  &:active {
    background-color: $silver;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__avatar-wrapper {
    position: relative;
    height: $size-8;
    width: $size-8;

    @media (min-width: 1024px) {
      margin-left: $size-2;
    }
  }

  &__avatar {
    border-radius: $account-button-avatar-br;
    flex-shrink: 0;
    height: $size-8;
    width: $size-8;
  }

  &__name {
    display: none;

    @media (min-width: 1024px) {
      display: block;
      font-family: $noto-sans-font-common;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      max-width: 130px;
    }
  }

  &__circle {
    line-height: 1;
    display: block;
    position: absolute;
    bottom: -3px;
    right: -1px;
    z-index: 1;
  }

  &--disabled {
    color: $silver;
    pointer-events: none;

    #{$root}__avatar {
      filter: grayscale(1);
    }

    #{$root}__circle {
      &::before {
        background-color: $silver;
      }
    }
  }

  &--active {
    color: $mine-shaft;
  }
}
