.takeover-banner {
  position: relative;
  height: 490px;
  width: 690px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &__close {
    $closeButton: &;
    background-color: #f4f4f4;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 12px;
    top: 12px;

    #{$closeButton}-icon {
      path {
        fill: #7a7a7a;
      }
    }

    &:hover {
      background-color: #e1e1e1;

      #{$closeButton}-icon {
        path {
          fill: #393939;
        }
      }
    }

    &:active {
      background-color: #c2c2c2;

      #{$closeButton}-icon {
        path {
          fill: #393939;
        }
      }
    }
  }

  &__close-icon {
    height: 16px;
    width: 16px;
  }

  &__link {
    display: block;
  }
}
