@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.text-button {
  border-radius: $button-background-br;
  background-color: transparent;
  color: $malibu;
  font-family: $font-stack-primary;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  position: relative;

  &::before {
    background-color: transparent;
    border-radius: $button-background-br;
    content: '';
    display: block;
    position: absolute;
    bottom: -8px;
    left: -12px;
    right: -12px;
    top: -8px;
  }

  &:hover {
    &::before {
      background-color: rgba($white, 0.05);
    }
    color: $cornflower-blue;
  }

  &:active {
    &:before {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }

  &--with-icon {
    svg {
      width: $size-6;
      height: $size-6;
      margin-right: $size-2;
    }
  }
}
