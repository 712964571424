@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;
@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.loader {
  background-size: cover;
  background-image: url('../../../../assets/images/png/loader.png');
  display: block;
  height: $size-6;
  width: $size-6;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
