.promo-plate {
  // background-image: url('../../../../../assets/images/promo-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 24px;
  font-family: 'Noto Sans', 'Noto Sans JP', 'Noto Sans HK',sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  padding: 48px 35px 54px;
  position: relative;

  &__discount {
    color: #421500;
    font-size: 22px;
    line-height: 1.36;
    text-align: center;
    margin: 0 auto 8px;
    max-width: 560px;

    strong {
      color: #a60000;
      white-space: nowrap;
    }
  }

  &__date {
    color: #a60000;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-top: 0;
    margin-bottom: 28px;
  }

  &__steps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__step {
    background: rgba(242, 236, 255, 0.76);
    backdrop-filter: blur(8px);
    border: 2px solid rgba(255, 137, 100, 0.64);
    border-radius: 24px;
    color: #2e1c1c;
    width: 232px;
    min-height: 220px;
    padding: 20px;

    p {
      margin: 0;
    }

    strong {
      color: #df5719;
      white-space: nowrap;
    }
  }

  &__step-head {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
  }

  &__step-title {
    margin: 0;
  }

  &__step-icon {
    flex-shrink: 0;
    height: 32px;
    width: 32px;
    margin-right: 12px;
  }

  &__note {
    color: rgba(66, 21, 0, 0.5);
    text-align: center;
    margin: 0;
  }

  .btn {
    border-radius: 100px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    font-family: 'Poppins', 'Noto Sans', 'Noto Sans JP', 'Noto Sans HK', sans-serif;
    font-size: 24px;
    line-height: 1.33;
    height: 56px;
    width: 290px;
    margin: 0;
    position: absolute;
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
  }

  .btn--green:hover {
    background-color: #54b210;
  }

  .btn--blue:hover {
    background-color: #0999d8;
  }
}
