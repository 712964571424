@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;

.footer {
  position: relative;
  z-index: 1;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-height: 85px;
  min-height: 85px;
  margin-top: -20px;
  padding: 12px;
  border-radius: 20px 20px 0 0;
  background: #ffffff;
  font-size: 14px;
  line-height: 16px;
  color: #393939;
  box-sizing: border-box;
  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;
    &-item {
      padding: 0 16px;
    }

    &-link {
      font-family: $noto-sans-font-common;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #7a7a7a;
    }
  }
}

.copyright {
  font-family: $noto-sans-font-common;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 8px;

  span {
    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }
}

.profile {
  background: $cloud-burst!important;

  span {
    color: $blue-haze!important;
  }

  button {
    color: $malibu-2!important;
  }
}


