@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;
@use 'src/Profile/assets/styles/abstracts/variables/shadows' as *;

.notification {
  border-radius: $middle-br;
  display: flex;
  align-items: center;
  font-family: $font-stack-secondary;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  width: 600px;
  padding: $size-5 $size-8 $size-5 $size-5;
  position: fixed;
  z-index: 3;

  &__icon {
    height: 18px;
    width: 18px;
    flex-shrink: 0;
    margin-right: $size-3;
  }

  &__text {
    padding-right: $size-6;
    margin-bottom: 0;
    margin-top: 0;
  }

  &__close {
    line-height: 1;
    flex-shrink: 0;
    margin-left: auto;
  }

  &--error {
    background-color: #f3cbc9;
    color: $pomegranate;
  }

  &--success {
    background-color: $frost;
    color: $camarone;
  }

  &--top {
    left: 50%;
    top: $size-14;
    transform: translateX(-50%);
  }

  &--topLeft {
    left: $size-14;
    top: $size-14;
  }

  &--topRight {
    right: $size-14;
    top: $size-14;
  }

  &--bottom {
    left: 50%;
    bottom: $size-14;
    transform: translateX(-50%);
  }

  &--bottomLeft {
    bottom: $size-14;
    left: $size-14;
  }

  &--bottomRight {
    bottom: $size-14;
    right: $size-14;
  }

  &--animateOpen {
    animation: bounce-in 1s ease-in;
  }

  &--animateClose {
    animation: bounce-out 1s ease-in;
  }

  @keyframes bounce-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes bounce-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
