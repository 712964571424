@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;

.time {
  display: flex;
  margin-bottom: 8px;
  line-height: 24px;
  span {
    font-family: $noto-sans-font-common;
    color: #525252;
    font-size: 16px;
    font-weight: 500;
    white-space: pre-wrap;
  }
  &__timeUnit {
    display: inline-block;
    padding: 0 4px 0 4px;
    background-color: #F4F4F4;
    border-radius: 4px;
  }
}

.urgency {
  span {
    color: #EF6C00!important;
  }
  .time__timeUnit {
    display: inline-block;
    padding: 0 4px 0 4px;
    background-color: #FFE0B2!important;
    border-radius: 4px;
  }
}