@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;

.w-container {
  margin: 0 auto;
  padding-top: 0.1px;
  position: relative;

  &--type-1 {
    max-width: 1030px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &--type-2 {
    width: 470px;
  }

  &--type-3 {
    width: 636px;
  }

  &--type-4 {
    max-width: 1030px;
    padding-left: 24px;
    padding-right: 24px;
  }
}
