@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;

.popup {
  $root: &;

  background-color: $pickled-bluewood;
  border-radius: $middle-br;
  width: 374px;
  padding: $size-4;
  position: absolute;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.32)) drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.24));

  &__close {
    line-height: 1;
    position: absolute;
    right: $size-4;
    top: $size-4;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__avatar {
    border-radius: $extra-middle-br;
    height: $size-12;
    width: $size-12;
    align-self: flex-start;
  }

  &__parag {
    margin: 0 $size-8 0 $size-3;
  }

  &__btn {
    width: 100%;
    margin-top: $size-4;
  }

  &__username {
    line-break: anywhere;
    color: $white;
  }
}
