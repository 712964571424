@use 'src/Profile/assets/styles/abstracts/variables/colors' as *;
@use 'src/Profile/assets/styles/abstracts/variables/fonts' as *;
@use 'src/Profile/assets/styles/abstracts/variables/sizes' as *;
@use 'src/Profile/assets/styles/abstracts/variables/border-radius' as *;

.header {
  background-color: $cloud-burst;
  border-radius: $wrapper-small-br;
  max-width: 100vw;
  padding-bottom: $size-16;
  padding-top: $size-8;
  margin-top: $size-21;
}
