@keyframes bgAnimation {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.9;
  }
}
.game {
  &__slider {
    margin: 0 -10px 0.9375rem;

    &--modal {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50rem;
      outline: none;
      border-radius: 24px;

      .swiper {
        &-container {
          padding: 50px;
        }
        &-slide {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-height: 100%;
            border-radius: 30px;
          }
        }
        &-button {
          &-prev,
          &-next {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            background: linear-gradient(#fff 0, #e2e2e2 100%);
            border-radius: 50%;

            &:after {
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }
      .close-button {
        z-index: 999;
        top: 58px;
        right: 58px;
      }
    }

    .slider {
      &__list {
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 4rem;

        &:after,
        &:before {
          content: '';
          position: absolute;
          top: 0;
          z-index: 2;
          width: 5px;
          height: 100%;
          background-color: #efefef;
        }

        &:after {
          left: 0;
        }

        &:before {
          right: 0;
        }

        .swiper {
          &-button {
            &-prev,
            &-next {
              bottom: 20px;
              top: auto;
              z-index: 51;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 1.875rem;
              height: 1.875rem;
              background: #ffffff;
              border-radius: 50%;
              opacity: 1;
              box-shadow: 0 8px 16px rgba(119, 119, 119, 0.3);

              &:after {
                font-size: 0.75rem;
                font-weight: bold;
                color: #0085c5;
              }
            }
            &-prev {
              left: 10px;
            }
            &-next {
              right: 10px;
            }
          }
          &-scrollbar {
            left: 0;
            right: 0;
            bottom: 33px;
            width: 100%;
            max-width: calc(100% - 80px);
            height: 0.25rem;
            margin: auto;
            background-color: #0085c5;

            &-drag {
              width: 1.875rem !important;
              height: 1.875rem;
              border: 0.6875rem solid #ffffff;
              border-radius: 50%;
              margin-top: -0.8125rem;
              background: #0085c5;
              box-sizing: border-box;
              box-shadow: 0 8px 16px rgba(119, 119, 119, 0.3);
              cursor: pointer;
            }
          }
        }
      }
      &__item {
        display: block;
        box-sizing: border-box;
        padding: 0 5px;
        height: 190px;
        overflow: hidden;
        &--img {
          border-radius: 24px;
          box-shadow: 0 3px 6px rgba(119, 119, 119, 0.3);
          overflow: hidden;
          cursor: pointer;
        }
        &--status {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 24px;
          width: 100%;
          height: 193px;
          &-loading {
            background-color: white;
            animation-name: bgAnimation;
            animation-duration: 700ms;
            animation-timing-function: ease-out;
            animation-direction: reverse;
            animation-iteration-count: infinite;
            opacity: 0;
          }
          &-error {
            background-color: #dddddd;
          }
        }
      }
      &__reload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        &-button {
          display: inline-flex;
          align-items: center;
          justify-content: space-around;
          background-color: white;
          border-color: transparent;
          box-shadow: 0 8px 16px rgba(119, 119, 119, 0.3);
          padding: 6px 12px;
          border-radius: 32px;
          font-family: 'Noto Sans', sans-serif;
          font-size: 16px;
          color: #0085c5;
          font-weight: 500;
          line-height: 20px;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            box-shadow: 0 30px 30px rgba(119, 119, 119, 0.3);
          }
          &:active {
            margin-top: 10px;
          }
        }
      }
      &__noLoaded {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 255px;
        background-color: #dddddd;
        border-radius: 24px;
      }
    }
  }
}

.hideButtons {
  div[class^='swiper-button'] {
    display: none !important;
  }
  div[class^='swiper-scrollbar'] {
    display: none !important;
  }
}

.screenshotSlide {
  display: block;
  height: 100%;
  border-radius: 20px;
  background-size: cover;
  background-repeat: no-repeat;
}

.bigSlide {
  display: block !important;
  width: 700px !important;
  height: 525px;
}
