@use '../abstracts/variables/colors' as *;
@use '../abstracts/variables/fonts' as *;

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: $gallery;
  color: $mine-shaft;
  font-family: $font-stack-secondary;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  min-width: 320px;
  width: 100%;
}

html,
body {
  height: 100%;
}

a {
  color: $lochmara;
  font-size: inherit;
  text-decoration: none;
}

picture {
  display: block;
}

figure {
  margin: 0;
}

iframe {
  border: 0;
}

strong {
  font-family: $font-stack-secondary;
  font-weight: bold;
}

button {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  font-weight: inherit;
  padding: 0;
}

input {
  caret-color: #000;
  //fix for input in ie11, earlier edge (remove red outline when submit form with input with wrong data)
  outline: none;
}

a:hover,
a:visited,
a:focus,
a:active,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  margin-top: 0;
}

/* stylelint-disable */
button:hover,
input[type='submit']:hover,
input[type='checkbox']:hover,
input[type='radio']:hover,
input[type='radio'] + label:hover,
input[type='checkbox'] + label:hover {
  cursor: pointer;
}
/* stylelint-enable */

button > * {
  position: relative;
}

ul {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

:focus {
  outline: none;
}

//delete cross from input in ie and edge
input::-ms-clear {
  display: none;
}

//fix bug in ie11 with default password eye icon
::-ms-reveal {
  display: none;
}

// delete autofill icon in safari
input::-webkit-contacts-auto-fill-button {
  display: none;
  pointer-events: none;
  position: absolute;
  right: 0;
  visibility: hidden;
}
